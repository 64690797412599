import { SetupLayout } from '@setup/components'
import { Localization } from '@workfront/localize-react'
import SystemExtraLargeHeroIcon from 'phoenix-icons/dist/SystemExtraLargeHeroIcon.js'
import React from 'react'

import { fallbackMap } from '../i18n'

const JumpSeatLayout = ({ children }) => {
  return (
    <SetupLayout
      contextSensitiveHelpPageID="csh-content-setup-jumpseat"
      headerIcon={<SystemExtraLargeHeroIcon />}
      title={
        <Localization
          messageKey="custom.product.guidance"
          fallback={fallbackMap['custom.product.guidance']}
        />
      }
      section={<Localization messageKey="setup" fallback="Setup" />}
    >
      {children}
    </SetupLayout>
  )
}

export default JumpSeatLayout
