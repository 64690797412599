import { Alert, Button, Input, Radio, RadioGroup } from '@phoenix/all'
import { useLocalization } from '@workfront/localize-react'
import React, { SyntheticEvent } from 'react'
import { css } from 'react-emotion'

import { validateJumpSeatConnection } from '../api'
import { checkIsValidJumpseatUrl } from '../api/validate-jumpseat-connection'
import {
  INTERNAL_ERROR,
  INVALID_STATUS,
  INVALID_TOKEN,
  INVALID_URL,
  VALID,
} from '../constants'
import { fallbackMap } from '../i18n'
import { JumpSeatState } from '../types'

type Props = {
  jumpSeatConfig: JumpSeatState
  setJumpSeatConfig: (jumpSeatConfig: JumpSeatState) => void
  errorKey: string
  setErrorKey: (errorKey: string) => void
  urlErrorKey: string
  setUrlErrorKey: (urlError: string) => void
}

export const JumpSeatConfigurationContent = ({
  jumpSeatConfig,
  setJumpSeatConfig,
  errorKey,
  setErrorKey,
  urlErrorKey,
  setUrlErrorKey,
}: Props) => {
  const { url, accessToken, isActive } = jumpSeatConfig

  const errorMessages = {
    [VALID]: useLocalization('status.valid', fallbackMap['status.valid']),
    [INVALID_URL]: useLocalization(
      'status.invalid.url',
      fallbackMap['status.invalid.url']
    ),
    [INVALID_TOKEN]: useLocalization(
      'status.invalid.token',
      fallbackMap['status.invalid.token']
    ),
    [INVALID_STATUS]: useLocalization(
      'status.invalid.integrationStatus',
      fallbackMap['status.invalid.integrationStatus']
    ),
    [INTERNAL_ERROR]: useLocalization(
      'error.internal',
      fallbackMap['error.internal']
    ),
  }

  const labels = {
    subHeader: useLocalization(
      'jumpseat.subheader',
      fallbackMap['jumpseat.subheader']
    ),
    testConnection: useLocalization(
      'action.test.connection',
      fallbackMap['action.test.connection']
    ),
    urlLabel: useLocalization(
      'jumpseat.url.label',
      fallbackMap['jumpseat.url.label']
    ),
    urlPlaceholder: useLocalization(
      'jumpseat.url.placeholder',
      fallbackMap['jumpseat.url.placeholder']
    ),
    tokenLabel: useLocalization(
      'jumpseat.token.label',
      fallbackMap['jumpseat.token.label']
    ),
    tokenPlaceholder: useLocalization(
      'jumpseat.token.placeholder',
      fallbackMap['jumpseat.token.placeholder']
    ),
    status: useLocalization(
      'jumpseat.status.label',
      fallbackMap['jumpseat.status.label']
    ),
    active: useLocalization('active', fallbackMap['active']),
    inActive: useLocalization('inactive', fallbackMap['inactive']),
  }

  const onUrlInputChangeHandler = (
    event: SyntheticEvent<HTMLInputElement>
  ) => {
    const newUrl = (event.target as HTMLInputElement).value || ''
    if(!checkIsValidJumpseatUrl(newUrl)) {
      setUrlErrorKey(INVALID_URL)
    } else {
      setUrlErrorKey(VALID)
    }

    onInputChangeHandler(event, 'url')
  }

  const onInputChangeHandler = (
    event: SyntheticEvent<HTMLInputElement>,
    key: string
  ) => {
    const value = (event.target as HTMLInputElement).value
    setJumpSeatConfig({
      ...jumpSeatConfig,
      [key]: value ? value : '',
    })
  }

  const testConnectionHandler = async () => {
    const response = await validateJumpSeatConnection(url, accessToken)
    const { status } = response
    switch (status) {
      case 'valid':
        setErrorKey(VALID)
        break
      case 'inactive':
        setErrorKey(INVALID_STATUS)
        break
      case 'url_not_found':
        setErrorKey(INVALID_URL)
        break
      case 'invalid_token':
        setErrorKey(INVALID_TOKEN)
        break
      case 'error':
        setErrorKey(INTERNAL_ERROR)
        break
      case 'default':
        setErrorKey('')
        break
    }

    setJumpSeatConfig({
      ...jumpSeatConfig,
      isActive: status === 'valid',
    })
  }

  return (
    <div className={rootWrapper}>
      <div className={subHeader}>{labels.subHeader[0]}</div>
      <div className={urlContainer}>
        <Input
          name="jumpseat-url-input"
          testID={'jumpseat-url-input'}
          label={labels.urlLabel[0]}
          placeholder={labels.urlPlaceholder[0]}
          value={url}
          onChange={onUrlInputChangeHandler}
          error={urlErrorKey !== VALID && errorMessages[urlErrorKey]}
        />
      </div>
      {errorKey === INVALID_URL && (
        <Alert
          aria={{ role: 'alert' }}
          testID="jumpseat-invalid-url"
          body={errorMessages[errorKey][0]}
          type="warning"
          className={alertWrapper}
        />
      )}
      <div className={tokenWrapper}>
        <div className={tokenContainer}>
          <Input
            name="jumpseat-token-input"
            testID={'jumpseat-token-input'}
            label={labels.tokenLabel[0]}
            placeholder={labels.tokenPlaceholder[0]}
            value={accessToken}
            onChange={(event: SyntheticEvent<HTMLInputElement>) =>
              onInputChangeHandler(event, 'accessToken')
            }
          />
        </div>
        <Button
          secondary
          data-testid="test-connection"
          onClick={testConnectionHandler}
          className={css`
            margin-top: 10px;
          `}
        >
          {labels.testConnection[0]}
        </Button>
      </div>
      {errorKey === 'valid' && (
        <Alert
          testID="jumpseat-valid"
          body={errorMessages[errorKey][0]}
          type="info"
          className={alertWrapper}
        />
      )}
      {errorKey === INVALID_TOKEN && (
        <Alert
          aria={{ role: 'alert' }}
          testID="jumpseat-invalid-token"
          body={errorMessages[errorKey][0]}
          type="warning"
          className={alertWrapper}
        />
      )}
      <div className={radioGroupWrapper}>
        <RadioGroup legend={labels.status[0]}>
          <Radio
            name={'active'}
            value={'active'}
            label={labels.active[0]}
            disabled={!(errorKey === VALID || isActive)}
            onChange={() =>
              setJumpSeatConfig({
                ...jumpSeatConfig,
                isActive: true,
              })
            }
            checked={isActive}
            testID={'radio-active'}
          />
          <Radio
            name={'inActive'}
            value={'inActive'}
            label={labels.inActive[0]}
            onChange={() =>
              setJumpSeatConfig({
                ...jumpSeatConfig,
                isActive: false,
              })
            }
            checked={!isActive}
            testID={'radio-inactive'}
          />
        </RadioGroup>
      </div>
      {errorKey === INVALID_STATUS && (
        <Alert
          aria={{ role: 'alert' }}
          testID="jumpseat-invalid-status"
          body={errorMessages[errorKey][0]}
          type="warning"
          className={alertWrapper}
        />
      )}

      {errorKey === INTERNAL_ERROR && (
        <Alert
          aria={{ role: 'alert' }}
          testID="jumpseat-internal-error"
          body={errorMessages[errorKey][0]}
          type="warning"
          className={alertWrapper}
        />
      )}
    </div>
  )
}

const rootWrapper = css`
  height: 78vh;
  padding: 20px 30px;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  label,
  legend {
    font-weight: 700;
    font-size: 13px;
    color: #767676;
  }
`

const tokenWrapper = css`
  display: flex;
  align-items: center;
`
const radioGroupWrapper = css`
  margin: 10px 0;
`

const subHeader = css`
  padding-bottom: 20px;
  font-weight: 400;
  color: #333333;
`

const urlContainer = css`
  width: 30%;
`

const alertWrapper = css`
  width: 40%;
  padding-bottom: 10px;
`

const tokenContainer = css`
  width: 40%;
  margin-right: 40px;
`
