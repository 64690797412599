// @ts-ignore
import { Phoenix } from '@phoenix/all'
import React from 'react'
import { Routes, Route } from 'react-router-dom'

import { JumpSeatLocalizationProvider } from '../i18n'
import JumpSeatConfigurationPage from '../jumpseat-configure-page'
import { JumpSeatAccessProvider } from './access-provider'

export const JumpSeatApp = () => {
  return (
    <JumpSeatAccessProvider>
      <Phoenix injectContainers={false}>
        <JumpSeatLocalizationProvider>
          <Routes>
            <Route path={'*'} element={<JumpSeatConfigurationPage />} />
          </Routes>
        </JumpSeatLocalizationProvider>
      </Phoenix>
    </JumpSeatAccessProvider>
  )
}
