import { JUMSEAT_API_PATH } from '../../constants'

type Response = {
  status: string
}

export function checkIsValidJumpseatUrl(url: string): boolean {
  return !!url.match(/^https:\/\/[\w-.]*\.jumpseat\.io$/i)
}

export default function validateJumpSeatConnection(
  url: string,
  token: string
): Promise<Response> {
  if (!checkIsValidJumpseatUrl(url)) {
    return Promise.resolve({
      status: 'invalid_url'
    })
  }

  return fetch(`${url}/${JUMSEAT_API_PATH}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      url: window.location.origin,
      token,
    }),
  })
    .then((response) => response.json())
    .then((data) => {
      return data
    })
    .catch(() => {
      return {
        status: 'error',
      }
    })
}
