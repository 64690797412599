import { getAuthHeaders, wfetch } from '@wf-mfe/api'

import { SERVICE_JUMPSEAT_PATH } from '../../constants'
import { JumpSeat, JumpSeatPostPayload } from '../../types'

export const getJumpSeatConfiguration = (): Promise<JumpSeat> => {
  return wfetch(`${SERVICE_JUMPSEAT_PATH}/configuration`, {
    method: 'GET',
    headers: getAuthHeaders(),
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(`${response.status}: ${response.statusText}`)
      }

      return response
    })
    .then((okResponse) => okResponse.json())
    .then((data) => data)
    .catch((error: unknown) => {
      // @ts-ignore
      const statusCode = error.message.split(':')[0].trim()
      return { statusCode, id: undefined }
    })
}

export const saveJumpSeatConfiguration = (
  method: 'POST' | 'PATCH',
  payload: JumpSeatPostPayload
): Promise<JumpSeat> => {
  return wfetch(`${SERVICE_JUMPSEAT_PATH}/configuration`, {
    method,
    headers: {
      'Content-Type': 'application/json',
      ...getAuthHeaders(),
    },
    body: JSON.stringify(payload),
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(`${response.status}: ${response.statusText}`)
      }

      return response
    })
    .then((okResponse) => okResponse.json())
    .then((data) => data)
    .catch((error: unknown) => {
      // @ts-ignore
      const statusCode = error.message.split(':')[0].trim()
      return { statusCode, id: undefined }
    })
}
