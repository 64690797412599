import { Button } from '@phoenix/all'
import { Localization } from '@workfront/localize-react'
import React from 'react'
import { css } from 'react-emotion'

export const JumpSeatFooter = ({
  actionDisabled,
  saveConfiguration,
  cancelConfiguration,
}): JSX.Element => {
  return (
    <div className={rootWrapper}>
      <div>
        <Button
          primary
          disabled={actionDisabled}
          data-testid="save"
          onClick={saveConfiguration}
        >
          <Localization messageKey={'action.save'} fallback={'Save'} />
        </Button>
        <Button
          disabled={actionDisabled}
          text
          data-testid="cancel"
          onClick={cancelConfiguration}
        >
          <Localization messageKey={'action.cancel'} fallback={'Cancel'} />
        </Button>
      </div>
    </div>
  )
}

const rootWrapper = css`
  padding: 16px 24px;
  border-top: 1px solid #e0e1e3;
  background: white;
  display: flex;
  justify-content: space-between;
  width: 100%;
`
