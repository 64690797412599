import { createReactLifecycles } from 'single-spa-workfront'

import { JumpSeatApp } from './jumpseat-app'

export const JumpseatConfigurationApp = JumpSeatApp

const lifecycles = createReactLifecycles({
  rootComponent: JumpseatConfigurationApp,
  rootNode: {
    className: 'flex flex-col flex-1',
  },
})

export const bootstrap = lifecycles.bootstrap
export const mount = lifecycles.mount
export const unmount = lifecycles.unmount

export default JumpseatConfigurationApp
