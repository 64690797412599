import {
  LoadLocalizations,
  LocalizationProvider,
} from '@workfront/localize-react'
import React from 'react'

import fallbackMap from './fallbacks'

export const clientFactory = () => ({
  namespace: ['jumpseat', 'redrock'],
  enableTerminology: true,
})

type Props = {
  children: React.ReactNode
}

export const JumpSeatLocalizationProvider = ({ children }: Props) => {
  return (
    <LocalizationProvider clientFactory={clientFactory}>
      <LoadLocalizations messageKeys={Object.keys(fallbackMap)}>
        {children}
      </LoadLocalizations>
    </LocalizationProvider>
  )
}
