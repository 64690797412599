import {
  LICENSE_TYPES,
  // @ts-ignore
  PACKAGING_OPTIONS,
  PermitAccess,
} from '@wf-mfe/auth'
// @ts-ignore
import { useIsTreatmentEnabled } from '@wf-mfe/toggles'
import React from 'react'

type Props = {
  children?: React.ReactNode
}

export const JumpSeatAccessProvider = ({ children }: Props) => {
  return (
    <PermitAccess
      // @ts-ignore
      packagingOption={PACKAGING_OPTIONS.JumpSeatIntegration}
      licenseType={LICENSE_TYPES.FULL}
      redirect="/setup"
    >
      {children}
    </PermitAccess>
  )
}
