export default {
  'custom.product.guidance': 'JumpSeat Integration' as const,
  setup: 'Setup' as const,
  'jumpseat.subheader':
    'Integrate JumpSeat with Workfront to create custom, in-product guidance.' as const,
  'action.test.connection': 'Test Connection' as const,
  'jumpseat.url.label': 'JumpSeat URL' as const,
  'jumpseat.url.placeholder': 'Enter JumpSeat URL...' as const,
  'jumpseat.token.label': 'JumpSeat integration token' as const,
  'jumpseat.token.placeholder': 'Enter JumpSeat integration token...' as const,
  'jumpseat.status.label': 'JumpSeat integration status' as const,
  'jumpseat.save.success':
    'JumpSeat configuration has been saved successfully' as const,
  'webhooks.http.server.error':
    'Internal Server Error. Please try again later or contact Workfront support for assistance.' as const,
  active: 'Active' as const,
  inactive: 'Inactive' as const,
  'action.save': 'Save' as const,
  'action.cancel': 'Cancel' as const,
  'status.valid': 'Configuration for JumpSeat is valid' as const,
  'error.internal':
    'Internal Server error occurred, Please check the application connection in JumpSeat and try to test the configuration again.' as const,
  'status.invalid.url':
    'The JumpSeat URL is invalid. Please check your JumpSeat URL and try to test the configuration again.' as const,
  'status.invalid.token':
    'The JumpSeat integration token is invalid. Please check your JumpSeat application configuration page to obtain a valid token or to enable this Workfront application.' as const,
  'status.invalid.integrationStatus':
    'This JumpSeat application is not active. Please check the status of the application connection in JumpSeat and try to test the configuration again.' as const,
}
