import React, { useEffect, useState } from 'react'
import { toast } from '@phoenix/all'
import { SetupLayoutContent, SetupLayoutFooter } from '@setup/components'
import { useLocalization } from '@workfront/localize-react'

import { getJumpSeatConfiguration, saveJumpSeatConfiguration } from '../api'
import { JumpSeatConfigurationContent } from '../configuration-content'
import { VALID } from '../constants'
import { JumpSeatFooter } from '../footer'
import { fallbackMap } from '../i18n'
import JumpSeatLayout from '../jumpseat-layout'
import { JumpSeat } from '../types'

export default function JumpSeatConfigurationPage() {
  const [jumpSeatConfig, setJumpSeatConfig] = useState({
    url: '',
    accessToken: '',
    isActive: false,
    id: '',
  })

  const [defaultConfig, setDefaultConfig] = useState<JumpSeat | null>(null)

  const [errorKey, setErrorKey] = useState('')
  const [urlErrorKey, setUrlErrorKey] = useState('')

  const labels = {
    internal_server_error: useLocalization(
      'webhooks.http.server.error',
      fallbackMap['webhooks.http.server.error']
    ),
    success: useLocalization(
      'jumpseat.save.success',
      fallbackMap['jumpseat.save.success']
    ),
  }

  const errorMessage = labels.internal_server_error[0]
  const successMessage = labels.success[0]

  useEffect(() => {
    const fetchJumpSeatConfiguration = async () => {
      const response = await getJumpSeatConfiguration()
      if (response && response.id) {
        updateStateConfiguration(response)
      }
    }

    fetchJumpSeatConfiguration()
  }, [])

  const updateStateConfiguration = (response: JumpSeat) => {
    setDefaultConfig(response)
    setJumpSeatConfig({
      url: response.url,
      accessToken: response.jumpSeatAccessToken,
      isActive: response.isActive,
      id: response.id,
    })
  }

  const saveConfiguration = async () => {
    const method = jumpSeatConfig.id ? 'PATCH' : 'POST'
    const { url, isActive, accessToken } = jumpSeatConfig
    const response = await saveJumpSeatConfiguration(method, {
      url,
      isActive,
      jumpSeatAccessToken: accessToken,
    })

    if (response && response.id) {
      updateStateConfiguration(response)
      toast.success(successMessage)
    } else {
      toast.error(errorMessage)
    }
  }

  const cancelConfiguration = async () => {
    const response = await getJumpSeatConfiguration()
    if (response && response.id) {
      updateStateConfiguration(response)
    } else {
      setJumpSeatConfig({
        url: '',
        accessToken: '',
        isActive: false,
        id: '',
      })
    }
  }

  // Show Save button enabled only when user updates/enters configuration
  const checkForConfigurationChanges = () => {
    const hasConfig = jumpSeatConfig.url && jumpSeatConfig.accessToken
    if (!defaultConfig && hasConfig) return true
    if (hasConfig && defaultConfig) {
      return (
        jumpSeatConfig.url !== defaultConfig.url ||
        jumpSeatConfig.accessToken !== defaultConfig.jumpSeatAccessToken ||
        jumpSeatConfig.isActive !== defaultConfig.isActive
      )
    }
    return false
  }

  const isSaveEnabled = checkForConfigurationChanges() && (urlErrorKey === VALID || urlErrorKey === '')

  return (
    <JumpSeatLayout>
      <>
        <SetupLayoutContent>
          <JumpSeatConfigurationContent
            jumpSeatConfig={jumpSeatConfig}
            setJumpSeatConfig={setJumpSeatConfig}
            errorKey={errorKey}
            setErrorKey={setErrorKey}
            urlErrorKey={urlErrorKey}
            setUrlErrorKey={setUrlErrorKey}
          />
        </SetupLayoutContent>
        <SetupLayoutFooter sticky>
          <JumpSeatFooter
            actionDisabled={!isSaveEnabled}
            saveConfiguration={saveConfiguration}
            cancelConfiguration={cancelConfiguration}
          />
        </SetupLayoutFooter>
      </>
    </JumpSeatLayout>
  )
}
