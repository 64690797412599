export const BASE_JUMPSEAT_PATH = '/jumpseat/api/jumpseat'
export const SERVICE_JUMPSEAT_PATH = `${BASE_JUMPSEAT_PATH}/v1`
export const JUMSEAT_API_PATH = `api/token_check`
export const JUMPSEAT_PATH = `https://wftest.jumpseat.io/${JUMSEAT_API_PATH}`

export const INTERNAL_ERROR = 'internalError'
export const INVALID_STATUS = 'invalidStatus'
export const INVALID_URL = 'invalidUrl'
export const INVALID_TOKEN = 'invalidToken'
export const VALID = 'valid'
